import api, { setToken } from "../api";
import { authAction, authActionnew, openNotificationWithIcon, openNotificationWithIconErr, profileAction } from "./common";
import { forward, getLocalStorage, setLocalStorage, showError } from "../../helpers/utils";
import { clearSession, setSession } from "../store/cookies";
import { headerForm, headers } from "../constants";
import { getIdOnboard } from "../../helpers/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import {handleInvested} from "../../containers/authcont/register/indie/indieprocess"




// log into account
export const loginAccount = (payload, cb) => {
    localStorage.clear();
    
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(`/api/auth/login2`,payload)
        .then(res => {
            
            // cb && cb()
            var verified = res.data.data.userVerified
            var completed = res.data.data.onboardingCompleted
            var invested = res.data.data.invested
            
            //console.log("this is it");
            if (verified){

                if (completed){
                    setSession(res.data.data.token)
                    setToken(res.data.data.token)
                    setLocalStorage('userInfo', res.data.data);
                    setLocalStorage('individual', {...getLocalStorage('userInfo')})

                    if (invested !== 1){
                                       
                        openNotificationWithIcon('Contact your RM', 'Investment Error')
                        //window.location = '/auth/onboard/investment'
    
                    }else{                  
    
                        openNotificationWithIcon('Welcome to your dashboard', 'Welcome')
                        window.location ="/dashboard";
                    }


                } else {
                    openNotificationWithIconErr('Please complete your KYC', 'KYC Incomplete')
                    
                }


            } else {

                openNotificationWithIcon('Email is not Verified', 'Email Error')
                
            }

        })
        .catch(error => {
            let message = showError(error)
            if (message === 'Please confirm your email address.' || message.includes('Account Not verified') ) {
                //setLocalStorage('email', payload?.email);
                openNotificationWithIcon('Your Account is not Verified', 'Other Error');
                setTimeout(() => {
                    window.location = "/auth/verify-email";
                }, 5000);

                
              }
            
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        });


    };
};



export const logoutAccount = (cb) => {
    
    return dispatch => {

        api.post(`/api/auth/signout`)
        .then(res => {
         
            clearSession()
            // localStorage.clear()
            setTimeout(()=> {
                cb && cb()
            }, 200)

        })
        .catch(error => {

        });
    };
};

export const apiCustom = (url,data) => {
    
    return dispatch => {

        api.post(url,data)
        .then(res => {
         
            console.log(res.data)
            return res.data;         

        })
        .catch(error => {

            return error;  

        });
    };
};

export const verify2fa = (payload, cb) => {
   
    return dispatch => {
        dispatch(authAction({verLoad:true, verMessage:''}))
        api.post(`api/Auth/LoginWithOtp`,payload)
        .then(res => {
            // handle cookie settings
            // setSession(res.data.data.token)
            // setToken(res.data.data.token)
            // setLocalStorage('userInfo', res.data.data)
            // // handle navigation to dashboard
            // cb()

            dispatch(authAction({verLoad:false, verMessage:''}))
        })
        .catch(error => {

            // dispatch(authAction({verLoad:false, verMessage:error.data.message}))
            // let message = showError(error)
            // if (message === 'Please confirm your email address.' || message.includes('Account Not verified') ) {
            //     setLocalStorage('email', payload.email);
            //     forward('/auth/register?type=verification');
            //   }
            dispatch(authAction({verLoad:false, verMessage:showError(error)}))
        });
    };
};

export const verify2Account = (url, payload,cb) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, {
            email:payload.email,
            token:payload.token,
            contId:payload.contId
        })
        .then(res => {
            // localStorage.setItem('appUserId', res.data.data.id)
            cb && cb()
            openNotificationWithIcon('Account successfully verified','Account Verification')
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })
        .catch(error => {
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        //    dispatch(authAction({authLoad:false, authMessage: error.data.message}))
        });
    };
};

export const resendVerification = (payload,cb) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(`/api/auth/ResendVerificationCode/${payload}`)
        .then(res => {
            openNotificationWithIcon('Code has been sent','Account Verification')
            cb()
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })
        .catch(error => {
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        });
    };
};

//Verify Email
export const verifyEmail = (url, payload, cb) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, headers)
        .then(res => {

           
            setTimeout(()=> {
                cb && cb(res.data)
            }, 100)
            openNotificationWithIcon(res.data.message, 'Email Verification')
            dispatch(authAction({authLoad:false, authMessage:''}))
        })
        .catch(error => {
            console.log(error)
            openNotificationWithIconErr(showError(error), 'Email Verification Error')
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
             //dispatch(authAction({authLoad:false, authMessage:error.data.message}))
        });
    };
};

// forgot pass word 
export const resetPassword = (url, payload, cb) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, headers)
        .then(res => {
            setTimeout(()=> {
                cb && cb(res.data)
            }, 100)
            openNotificationWithIcon(res.data.message, 'Reset password')
            dispatch(authAction({authLoad:false, authMessage:''}))
        })
        .catch(error => {
            openNotificationWithIconErr(showError(error), 'Reset Password')
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
            // dispatch(authAction({authLoad:false, authMessage:error.data.message}))
        });
    };
};
//new create a ccount with a different name

export const createAccountnew = (url,payload, cb,header) => {

    //console.log(payload);

    return dispatch => {

        //dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, header? header:headers)
        .then(res => {

            console.log("succedss")
             localStorage.setItem('appUserId', res.data)
            cb && cb(res.data)
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })

        .catch(error => {
            console.log("errorro")
            
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        //    error && dispatch(authAction({authLoad:false, authMessage:!error.data.errors ? error.data.message || error.data.Message : formatErrorMessages(error.data.errors) || error.data.Message}))
        });
    };

};


// first step in creating an account_old one
export const createAccount = (url,payload, cb,header) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, header? header:headers)
        .then(res => {
            // localStorage.setItem('appUserId', res.data.data.id)
            localStorage.setItem('individual', JSON.stringify({id:res.data.data,email:payload.email}))
            console.log("From response: "+ res.data)
            cb && cb(res.data)
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })
        .catch(error => {
            
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        //    error && dispatch(authAction({authLoad:false, authMessage:!error.data.errors ? error.data.message || error.data.Message : formatErrorMessages(error.data.errors) || error.data.Message}))
        });
    };
};

// second step to update account
export const updateAccount = (url,payload, cb, type) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.patch(url, payload,headers)
        .then(res => {
            setTimeout(()=> {
                cb && cb(res.data)
                // setLocalStorage('newId', res.data?.data?.id)
            }, 100)
            dispatch(authAction({authLoad:false, authMessage:''}))
        })
        .catch(error => {
           
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
            // dispatch(authAction({authLoad:false, authMessage:error.response.data.errors ? formatErrorMessages(error.response.data.errors):error.response.data.message || error.response.data.Message }))
        });
    };
};

// second step to update account for put
export const updateAccountTwo = (url,payload, cb) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.put(url, payload,headers)
        .then(res => {
            setTimeout(()=> {
                cb && cb(res.data)
                setLocalStorage('newId', res.data?.data?.id)
            }, 100)
            dispatch(authAction({authLoad:false, authMessage:''}))
        })
        .catch(error => {
            // console.log(error.response.data)
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
            // dispatch(authAction({authLoad:false, authMessage:error.response.data.errors ? formatErrorMessages(error.response.data.errors):error.response.data.message || error.response.data.Message }))
        });
    };
};

export const uploadDocs = (url,payload, cb,header) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.put(url, payload)
        .then(res => {
            cb && cb(res.data)
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })
        .catch(error => {
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        });
    };
};

export const uploadDoc = (data,payload) => {
    return async (dispatch) => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        // api.post(`/api/Services/UploadFile/?fileType=${type}`, payload, headerForm)
        
        // .then(res => {
        //     dispatch(authAction({authLoad:false, authMessage:''}))
        //     // return {key:data.type}
        // })
        // .catch(error => {
        //     dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        // });
        try {
            const response = await api.post(`/api/Services/UploadFile/?fileType=${data.type}&description=${data.desc}&contId=${getIdOnboard()}`, payload, headerForm)
            dispatch(authAction({authLoad:false, authMessage:''}))
            return response.data;
        } catch (error) {
            dispatch(authAction({authLoad:false, authMessage:''}))
            throw error;
        }
    };
};

export const uploadCorpDocs = (url,payload, cb,header) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, header? header:headers)
        .then(res => {
            // localStorage.setItem('appUserId', res.data.data.id)
            cb && cb(res.data)
            setTimeout(()=> {
                dispatch(authAction({authLoad:false, authMessage:''}))
            }, 100)
            
        })
        .catch(error => {
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
        //    error && dispatch(authAction({authLoad:false, authMessage:!error.data.errors ? error.data.message : formatErrorMessages(error.data.errors) || error.data.Message}))
        });
    };
};

const finalizeOnb = ()=> {
    setTimeout(()=> {
        openNotificationWithIcon('Account successfully created', 'Onboarding')
        if (getLocalStorage('userInfo')){
            forward('/dashboard')
        }else {
            forward('/auth/login')
        }
        localStorage.removeItem('payment')
        getLocalStorage('individual') ? localStorage.removeItem('individual'): localStorage.removeItem('corporate')
    },50)
}

export const makePayment = (url,payload, cb,header) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.post(url, payload, header? header:headers)
        .then(res => {
            if (res.data.status === false){
                dispatch(authAction({authLoad:false, authMessage:JSON.parse(res.data.message).message}))
            } else {
                // window.open(res.data.data.authorization_Url)
                dispatch(authAction({authLoad:false, authMessage:''}))
                // api.get(`/user/CompleteOnboarding/${payload.id}`)
                // .then (()=> {
                //     setTimeout(()=> {
                //         openNotificationWithIcon('Account successfully created', 'Onboarding')
                //         if (getLocalStorage('userInfo')){
                //             forward('/dashboard')
                //         }else {
                //             forward('/auth/login')
                //         }
                //     },500)
               finalizeOnb()
                    // localStorage.clear();
            }
            
        })
        .catch(error => {
            var message = showError(error)
            if (message === "PaymentRef already exists" ) {
                finalizeOnb()
            } else {
                setLocalStorage('payment',payload)
            }
            dispatch(authAction({authLoad:false, authMessage:message}))
        });
    };
};

export const completeOnb = (payload, cb) => {
    return dispatch => {
        dispatch(authAction({authLoad:true, authMessage:''}))
        api.get(`/user/CompleteOnboarding/${payload.id}`)
        .then(res => {
            
            if (res.data.status === false){
                dispatch(authAction({authLoad:false, authMessage:JSON.parse(res.data.message).message}))
            } else {
            
                dispatch(authAction({authLoad:false, authMessage:''}))
                setTimeout(()=> {
                    openNotificationWithIcon('Account successfully created', 'Onboarding')
                    if (getLocalStorage('userInfo')){
                        window.location = '/dashboard'
                    }else {
                        window.location = '/auth/login'
                    }
                },500)
            }
            
            
        })
        .catch(error => {
            dispatch(authAction({authLoad:false, authMessage:showError(error)}))
            window.location = '/auth/login'
        });
    };
};