import axios from 'axios';
import { getSession } from './store/cookies';
import _ from 'lodash';

const settings = {
  // baseURL:'https://dev-api.lotuscapitallimited.com/api/',
  //baseURL:'https://test-lotus-api.lotuscapitallimited.com/',
   //baseURL:'https://lotusapi.lotuscapitallimited.com/',
  //baseURL:'http://integration.lotuscapitallimited.com:5034/',
   baseURL:'https://apiconnect.lotuscapitallimited.com/',
  //baseURL:'https://localhost:44323/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json,text/plain,*/*',
    // 'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Expose-Headers': 'X-Custom-Header, Content-Length'
  }
};

//export const baseUrl2 = "https://lotusapi.lotuscapitallimited.com/";
export const baseUrl2 = "https://apiconnect.lotuscapitallimited.com/";
//export const baseUrl2 = "https://localhost:44323/";


const instance = axios.create(settings);
const token = getSession()

if (token != null && !_.isEmpty(token)) {
  instance.defaults.headers.common.Authorization = `Bearer ${getSession()}`;
}

// eslint-disable-next-line 
export default {
  get(url, request) {
    return instance
      .get(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  post(url, request) {
    return instance
      .post(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response);
        }
        return Promise.reject(error);
      });
  },
  put(url, request) {
  return instance
      .put(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  patch(url, request) {
    return instance
      .patch(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  delete(url, request) {
    return instance
      .delete(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};

export const setToken = token => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
