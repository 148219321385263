import React, { useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { allRoutes } from "./routes/routes";
import { IdleTimerProvider } from 'react-idle-timer';
import { ProtectedRoute } from "./routes/protectedroute";
import { Error404 } from "./pages/error";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Homepage } from "./pages/homepage";

const queryClient = new QueryClient();

function App() {
  const idleTimerRef = useRef(null);
  const navigate = useNavigate();

  const onIdle = () => {
    // Perform logout operation here
    localStorage.clear();
    sessionStorage.clear();
    // Clear user session and redirect to login page
    // e.g., clear tokens, reset user state, etc.
    navigate('/auth/login');
  };

  return (
    <QueryClientProvider client={queryClient}>
      <IdleTimerProvider
        ref={idleTimerRef}
        timeout={1000 * 60 * 5} // 5 minutes idle time
        onIdle={onIdle}
        debounce={250}
      >
        <div>
          <Routes>
            <Route path="/" element={<Homepage />} />
            {allRoutes.map((item, index) => (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <ProtectedRoute auth={item.auth}>
                    <item.component />
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </IdleTimerProvider>
    </QueryClientProvider>
  );
}

export default App;
